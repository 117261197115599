import React, { useEffect, useMemo, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'

const PREFIX = 'GooglePlaceComplete'

const classes = {
    icon: `${PREFIX}-icon`,
}

const autocompleteService = { current: null }

const abbrState = (input, to) => {
    var states = [
        ['Arizona', 'AZ'],
        ['Alabama', 'AL'],
        ['Alaska', 'AK'],
        ['Arkansas', 'AR'],
        ['California', 'CA'],
        ['Colorado', 'CO'],
        ['Connecticut', 'CT'],
        ['Delaware', 'DE'],
        ['Florida', 'FL'],
        ['Georgia', 'GA'],
        ['Hawaii', 'HI'],
        ['Idaho', 'ID'],
        ['Illinois', 'IL'],
        ['Indiana', 'IN'],
        ['Iowa', 'IA'],
        ['Kansas', 'KS'],
        ['Kentucky', 'KY'],
        ['Louisiana', 'LA'],
        ['Maine', 'ME'],
        ['Maryland', 'MD'],
        ['Massachusetts', 'MA'],
        ['Michigan', 'MI'],
        ['Minnesota', 'MN'],
        ['Mississippi', 'MS'],
        ['Missouri', 'MO'],
        ['Montana', 'MT'],
        ['Nebraska', 'NE'],
        ['Nevada', 'NV'],
        ['New Hampshire', 'NH'],
        ['New Jersey', 'NJ'],
        ['New Mexico', 'NM'],
        ['New York', 'NY'],
        ['North Carolina', 'NC'],
        ['North Dakota', 'ND'],
        ['Ohio', 'OH'],
        ['Oklahoma', 'OK'],
        ['Oregon', 'OR'],
        ['Pennsylvania', 'PA'],
        ['Rhode Island', 'RI'],
        ['South Carolina', 'SC'],
        ['South Dakota', 'SD'],
        ['Tennessee', 'TN'],
        ['Texas', 'TX'],
        ['Utah', 'UT'],
        ['Vermont', 'VT'],
        ['Virginia', 'VA'],
        ['Washington', 'WA'],
        ['West Virginia', 'WV'],
        ['Wisconsin', 'WI'],
        ['Wyoming', 'WY'],
    ]

    if (to == 'abbr') {
        input = input.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
        for (let i = 0; i < states.length; i++) {
            if (states[i][0] == input) {
                return states[i][1]
            }
        }
    } else if (to == 'name') {
        input = input.toUpperCase()
        for (let i = 0; i < states.length; i++) {
            if (states[i][1] == input) {
                return states[i][0]
            }
        }
    }
}

const GooglePlaceComplete = (props) => {
    const [value, setValue] = useState(null)
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState([])

    const fetchPlace = useMemo(
        () =>
            throttle((request, callback) => {
                if (!autocompleteService.current && window.google) {
                    autocompleteService.current =
                        new window.google.maps.places.AutocompleteService()
                }
                if (autocompleteService.current) {
                    // request.types = [props.googlePlaceType]
                    if (props.defaultCountry) {
                        request.componentRestrictions = {
                            country: props.defaultCountry,
                        }
                    }
                    autocompleteService.current.getPlacePredictions(request, callback)
                }
                // request.types = [props.googlePlaceType]
                // request.componentRestrictions = { country: 'us' }
                // autocompleteService.current.getPlacePredictions(
                //     request,
                //     callback
                // )
            }, 200),
        [],
    )

    useEffect(() => {
        let active = true
        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService()
        }
        if (!autocompleteService.current) {
            return undefined
        }

        if (inputValue === '') {
            setOptions(value ? [value] : [])
            return undefined
        }
        fetchPlace({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = []
                let titleOptions = []

                if (value) {
                    newOptions = [value]
                }

                if (results) {
                    newOptions = [...newOptions, ...results]
                }
                newOptions.map((item) => {
                    titleOptions.push({ ...item, title: item.description })
                })
                setOptions(titleOptions)
            }
        })

        return () => {
            active = false
        }
    }, [value, inputValue, fetch])

    const fillLongitudeFromAddress = async (placeid) => {
        console.log('placeid', placeid)

        const request = {
            placeId: placeid,
            fields: ['name', 'formatted_address', 'place_id', 'geometry'],
        }
        const service = new google.maps.places.PlacesService(document.createElement('div'))
        service.getDetails(request, (place, status) => {
            if (
                status === google.maps.places.PlacesServiceStatus.OK &&
                place &&
                place.geometry &&
                place.geometry.location
            ) {
                props.formik.setFieldValue('latitude', place.geometry.location.lat())
                props.formik.setFieldValue('longitude', place.geometry.location.lng())
            }
        })
    }

    const fillZipFromAddress = async (address) => {
        fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                address,
            )}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.results) {
                    if (data.results[0].address_components) {
                        data.results[0].address_components.map((addr) => {
                            if (addr.types[0] === 'postal_code') {
                                if (props.zipField) {
                                    props.formik.setFieldValue(`${props.zipField}`, addr.long_name)
                                } else {
                                    props.formik.setFieldValue('zip', addr.long_name)
                                }
                            }
                        })
                    }
                }
            })
    }

    const fillStateCityFromAddress = async (address, state, city) => {
        fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                address,
            )}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.results) {
                    if (data.results[0].address_components) {
                        data.results[0].address_components.map((addr) => {
                            console.log(addr, 'srrtt')
                            if (state && addr?.types?.includes('administrative_area_level_1')) {
                                if (props.stateField) {
                                    props.formik.setFieldValue(
                                        `${props.stateField}`,
                                        addr.long_name,
                                    )
                                } else {
                                    props.formik.setFieldValue('state', addr.long_name)
                                }
                            }
                            if (city && addr?.types?.includes('locality')) {
                                if (props.cityField) {
                                    props.formik.setFieldValue(`${props.cityField}`, addr.long_name)
                                } else {
                                    props.formik.setFieldValue('city', addr.long_name)
                                }
                            }
                        })
                    }
                }
            })
    }

    const onChangeHandler = (event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options)
        let mainValue
        if (newValue && typeof newValue.structured_formatting !== 'undefined') {
            if (props.name == 'address1') {
                console.log(newValue.structured_formatting, 'newValue.structured_formatting')
                let split = newValue.structured_formatting?.secondary_text
                    ? newValue.structured_formatting.secondary_text.split(', ')
                    : ''
                // let state = abbrState(split[1], 'abbr')
                // prefill based on address
                props.formik.setFieldValue('city', split[0])
                props.formik.setFieldValue('state', split[1])
                // get zip
                fillZipFromAddress(newValue.description)

                if (props.placeID) {
                    fillLongitudeFromAddress(newValue.place_id)
                }

                if (props.stateCityFill) {
                    fillStateCityFromAddress(newValue.description, 1, 1)
                }

                if (props.fullAddress) {
                    props.formik.setFieldValue('full_address', newValue.description)
                    props.formik.setFieldValue('place_id', newValue.place_id)
                }
            }
            if (props.name == props.addressField) {
                let split = newValue.structured_formatting?.secondary_text
                    ? newValue.structured_formatting.secondary_text.split(', ')
                    : ''
                // let state = abbrState(split[1], 'abbr')
                // prefill based on address
                props.formik.setFieldValue(`${props.cityField}`, split[0])
                props.formik.setFieldValue(`${props.stateField}`, split[1])
                // get zip
                fillZipFromAddress(newValue.description)
            }
            if (props?.saveFullAddress) {
                mainValue = newValue.description
            } else {
                mainValue = newValue.structured_formatting.main_text
            }
        }
        props.formik.values[props.name] = mainValue
        props.formik.setFieldValue(props.name, mainValue)
        setValue(mainValue)
    }

    useEffect(() => {
        setValue(props.value)
    }, [props.value])
    return (
        <Autocomplete
            style={{ marginBottom: '30px' }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            // getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
            // renderOption={(props, option) => (
            //     <li {...props} key={option.title}>
            //         {' '}
            //         {option.title}{' '}
            //     </li>
            // )}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                onChangeHandler(event, newValue)
            }}
            onInputChange={(event, newInputValue) => {
                let tempValue = newInputValue.split(', ')[0]
                setInputValue(tempValue)
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                        }}
                        label={props.label}
                        id={props.id}
                        name={props.name}
                        value={value}
                        error={props.error}
                        helperText={props.helperText}
                        autoComplete="off"
                        variant="outlined"
                        fullWidth
                    />
                )
            }}
            renderOption={(option) => {
                if (typeof option.structured_formatting === 'undefined') return
                const matches = option.structured_formatting.main_text_matched_substrings
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                )

                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                                >
                                    {part.text}
                                </span>
                            ))}

                            <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            }}
        />
    )
}

export default GooglePlaceComplete
